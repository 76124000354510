@tailwind base;
@tailwind components;
@tailwind utilities; 

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav ul {
  list-style: none;
  padding: 0;
}

nav li {
  padding: 8px;
  position: relative;
}

nav li ul {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
}

nav li:hover ul {
  display: block;
}

.headline {
  font-size: 3.5rem;
  font-weight: 400;
  line-height: 2.5rem;
  margin-bottom: 1.5rem;
}

.text-description {
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
  margin-bottom: 1.5rem;
  color: #4a5568;
}

.header-cta-button {
  @apply rounded-lg text-black font-bold py-2 px-4;
  background-color: #FFBF78;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition-duration: 150ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* tailwindcss: shadow-lg */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.header-cta-button:hover {
  background-color: #f8a13f;
  color:#161e2e;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  transition-duration: 150ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* tailwindcss: shadow-lg */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cta-button {
  background-color: #FFBF78;
  cursor: pointer;
  border-radius: 0.25rem;
  color: #161e2e;
  display: inline-block;
  font-size: 1.00rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.7rem 1rem;
  text-decoration: none;
  transition-duration: 150ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* tailwindcss: shadow-lg */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cta-button:hover {
  background-color: #f8a13f;
  cursor: pointer;
  border-radius: 0.25rem;
  color: #161e2e;
  display: inline-block;
  font-size: 1.00rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.7rem 1rem;
  text-decoration: none;
  transition-duration: 150ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* tailwindcss: shadow-lg */
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.cta-learn-more {
  cursor: pointer;
  color: #905bf4;
  display: inline-block;
  font-size: 1.00rem;
  font-weight: 600;
  line-height: 1.25rem;
  padding: 0.7rem 1rem;
  text-decoration: none;
  transition-duration: 150ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);


}

.cta-learn-more:hover {
  cursor: pointer;
  color: #905bf4;
  display: inline-block;
  font-size: 1.00rem;
  font-weight: 800;
  line-height: 1.25rem;
  padding: 0.7rem 1rem;
  text-decoration: none;
  transition-duration: 150ms;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  /* tailwindcss: shadow-lg */

}

.widget-container {
  @apply flex flex-col items-center justify-center w-full px-8 py-16 lg:py-24 lg:px-32 space-y-4;
}

.widget-keyMessage-title{
  @apply text-left text-2xl lg:text-4xl font-black text-gray-700;
}

.widget-keyMessage-description {
  @apply text-left text-sm lg:text-base font-normal text-gray-600;
}

.widget-bulletPoints {
  @apply text-xs lg:text-sm text-left font-normal text-gray-600;
}
